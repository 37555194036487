.container {
  margin-top: 91px;
  padding: 10px 5%;
}

.file-form {
  display: flex;
  margin: 2rem 0;
  width: 100%;
  gap: 2rem;
}

button {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.file-form .file-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.file-form .file-form-group label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

select {
  max-width: 7rem;
}
