.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContentContainer {
  margin-top: 91px;
  padding: 10px 5%;
  width: 1200px;
}
