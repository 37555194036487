.TextArea {
  width: 100%;
  height: 300px;
  font-family: "Roboto", sans-serif;
  padding: 1rem;
  box-sizing: border-box;
  background-color: transparent;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
