.Button-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.InputFileButton {
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.DownloadFileButton {
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.CloseTabButton {
  top: 0;
  right: 0;
  position: absolute;
  background-color: transparent;
  border: none;
  color: red;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-transform: lowercase;
}

.CloseTabButton:hover {
  color: darkred;
}
