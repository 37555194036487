.Header {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  position: fixed;
  top: 0;
  z-index: 99;
}

.Header img {
  height: 100%;
  margin-left: 5%;
  cursor: pointer;
}

.Header .navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-right: 5%;
}

.Header .navigation a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
}

.Header .navigation a:hover {
  background-color: #fff;
  color: #000;
}
